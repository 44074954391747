import clsx from "clsx";
import ArticleCard from "./ArticleCard";

const ArticleGrid = ({ articles }) => {
  return (
    <div
      className={clsx(
        "group grid grid-cols-12 gap-y-6 2xl:container sm:gap-x-6 lg:gap-x-12 lg:gap-y-12"
      )}
    >
      {articles.map((article, index) => {
        return (
          <div
            key={article.id}
            className={clsx("group", {
              "col-span-12 sm:col-span-6 xl:col-span-7 2xl:col-span-4":
                index % 4 === 0 || index % 4 === 3,
              "col-span-12 sm:col-span-6 xl:col-span-5 2xl:col-span-4":
                index % 4 === 1 || index % 4 === 2,
            })}
          >
            <ArticleCard
              article={article}
              imageContainerClassName={clsx(
                "aspect-square relative flex md:h-96 3xl:h-128 w-full",
                {}
              )}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ArticleGrid;
