import cloudflareLoader from "@/lib/cloudflare-images";
import clsx from "clsx";
import Image from "next/image";
import Newsletter from "./Newsletter";

const NewsletterModule = () => {
  return (
    <div className={clsx("@container 2xl:container")}>
      <div
        className={clsx(
          "flex overflow-hidden rounded-2xl bg-zinc-950 text-zinc-50"
        )}
      >
        <div className={clsx("@3xl:w-1/2")}>
          <div className={clsx("flex h-full flex-col space-y-4 p-6 lg:p-12")}>
            <h2 className={clsx("heading-lg--thin")}>
              Recipe inspiration, straight into your inbox
            </h2>

            <p className={clsx("body-text")}>
              New recipes, cooking tips, and easy dinner ideas every week.
            </p>

            <div className={clsx("mt-auto")}>
              <Newsletter source="Homepage" />
            </div>
          </div>
        </div>
        <div className={clsx("@3xl:w-1/2")}>
          <div
            className={clsx(
              "relative aspect-square bg-zinc-100 @4xl:aspect-4/3"
            )}
          >
            <Image
              src="https://files.mob-cdn.co.uk/recipes/2023/12/Sausage-Gochujang-Rigatoni.jpg"
              layout="fill"
              objectFit="cover"
              alt="Sausage Gochujang Rigatoni"
              loader={cloudflareLoader}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsletterModule;
