export const getScheduleSlot = () => {
  const date = new Date();
  const hour = date.getHours();
  const day = date.getDay();
  const days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  const amPm = hour >= 12 ? "Pm" : "Am";
  return `${days[day]}${amPm}`;
};

export default getScheduleSlot;
