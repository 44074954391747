import CountIndicator from "@/components/CountIndicator";
import DietaryRequirements from "@/components/DietaryRequirements";
import useCurrentUser from "@/hooks/useCurrentUser";
import useCurrentUserDietaryRequirements from "@/hooks/useCurrentUserDietaryRequirements";
import {
  AdjustmentsVerticalIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import usePaywall from "@hooks/usePaywall";
import * as Popover from "@radix-ui/react-popover";
import clsx from "clsx";
import { observer } from "mobx-react";
import { useState } from "react";

const DietaryRequirementsPopover = observer(() => {
  const { setPaywall } = usePaywall();

  const [isOpen, setIsOpen] = useState(false);

  const Icon = isOpen ? XMarkIcon : AdjustmentsVerticalIcon;

  const { isLoggedIn, isLoggedOut, currentUser } = useCurrentUser();

  const { dietaryRequirements } = useCurrentUserDietaryRequirements();

  const handleClick = (ev) => {
    if (isLoggedOut || currentUser.plan !== 2) {
      setPaywall({
        isOpen: true,
        isClosable: true,
        variant: "personaliseExperience",
      });
    }
  };

  const handleSave = () => {
    setIsOpen(false);
  };

  const buttonClass = clsx(
    "w-component hover:bg-zinc-800 transition ease-out h-component flex items-center justify-center rounded-full bg-zinc-950 text-zinc-50 relative"
  );

  if (isLoggedIn && currentUser?.plan === 2) {
    return (
      <Popover.Root onOpenChange={setIsOpen} open={isOpen}>
        <Popover.Trigger className={buttonClass}>
          <Icon className={clsx("h-5 w-5")} />
          {dietaryRequirements.length > 0 && (
            <CountIndicator count={dietaryRequirements.length} />
          )}
        </Popover.Trigger>

        <Popover.Portal className={clsx("z-50")}>
          <Popover.Content
            className={clsx(
              "z-50 w-[calc(100vw-3rem)] max-w-96 rounded-2xl bg-zinc-950 p-6 text-zinc-50 shadow-lg"
            )}
            sideOffset={4}
            collisionPadding={24}
          >
            <Popover.Arrow
              className={clsx("fill-zinc-950")}
              width={15}
              height={7}
            />
            <div>
              <DietaryRequirements darkMode onSave={handleSave} />
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    );
  }

  return (
    <button className={buttonClass} onClick={handleClick}>
      <Icon className={clsx("h-5 w-5")} />
    </button>
  );
});

export default DietaryRequirementsPopover;
