import Rail from "@/components/Rail";
import getScheduleSlot from "@/src/rails/lib/getScheduleSlot";
import { observer } from "mobx-react";

const RailGroup = observer(({ railGroup }) => {
  const scheduleSlot = getScheduleSlot();

  const rail = railGroup?.[0]?.[scheduleSlot]?.[0];

  if (!rail) return null;

  // Get the current day, expressed as a number
  const currentDay = new Date().getDay();

  return <Rail rail={rail} shuffleSeed={currentDay} />;
});

export default RailGroup;
