import clsx from "clsx";

const ModuleHeading = ({ heading, subHeading = "" }) => {
  return (
    <h2
      className={clsx(
        "flex flex-col px-6 text-center lg:flex-row lg:justify-center lg:space-x-3"
      )}
    >
      <span className={clsx("heading-lg--spatial")}>{heading}</span>{" "}
      <span className={clsx("heading-lg--serif text-zinc-700")}>
        {subHeading}
      </span>
    </h2>
  );
};

export default ModuleHeading;
