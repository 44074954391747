import clsx from "clsx";
import dynamic from "next/dynamic";
import { useState } from "react";
import { useInView } from "react-intersection-observer";

const ReactPlayer = dynamic(() => import("react-player/lazy"), { ssr: false });

const BackgroundVideo = ({ url }: { url: string }) => {
  const [isReady, setIsReady] = useState(false);

  const { ref, inView } = useInView({});

  return (
    <div
      ref={ref}
      className={clsx(
        "h-full w-full overflow-hidden",
        "transition-opacity duration-1000 ease-in-out",
        {
          "opacity-0": !isReady,
          "opacity-100": isReady,
        }
      )}
    >
      <ReactPlayer
        url={url}
        className={clsx(
          "[&_video]:absolute [&_video]:inset-0 [&_video]:object-cover",
          "[&_video::-webkit-media-controls]:!hidden",
          "[&_video::-webkit-media-controls-panel]:!hidden",
          "[&_video::-webkit-media-controls-play-button]:!hidden",
          "[&_video::-webkit-media-controls-start-playback-button]:!hidden"
        )}
        playing={inView}
        loop
        controls={false}
        muted
        width="100%"
        height="100%"
        playsinline
        pip={false}
        onReady={() => setIsReady(true)}
        config={{
          file: {
            attributes: { disableRemotePlayback: true },
          },
        }}
      />
    </div>
  );
};

export default BackgroundVideo;
