import EntryCarousel from "@/components/EntryCarousel";
import clsx from "clsx";
import AlgoliaDataWrapper from "components/AlgoliaDataWrapper";

const ComingSoonCarousel = ({ heading, introduction }) => {
  return (
    <div className={clsx("relative")}>
      <div className={clsx("")}>
        <AlgoliaDataWrapper
          postDateInPast={false}
          postDateInFuture={true}
          render={(data) => (
            <div>
              <EntryCarousel
                entries={data.hits}
                aspectRatio="landscape"
                slideSize="large"
                disableRecipeActions
                thumbnailOverlay
                showSection
                disableLink
                headerComponent={
                  <div className={clsx("")}>
                    <_Header heading={heading} introduction={introduction} />
                  </div>
                }
              />
            </div>
          )}
        />
      </div>
    </div>
  );
};

const _Header = ({ heading, introduction }) => {
  return (
    <div className={clsx("w-[calc(100vw-10rem)] max-w-xs pr-6 lg:max-w-md")}>
      <div className={clsx("space-y-4 py-9 lg:py-9")}>
        <h2 className={clsx("heading-xl--thin w-48 lg:w-auto")}>{heading}</h2>
        <div
          dangerouslySetInnerHTML={{ __html: introduction }}
          className={clsx("text-base font-normal text-zinc-500 lg:text-lg")}
        ></div>
      </div>
    </div>
  );
};

const _EntryCarousel = ({ entries }) => {
  return <></>;
};

export default ComingSoonCarousel;
