import Link from "next/link";

const Target = ({
  className = "",
  target,
  children,
  onClick = () => null,
  openInNewWindow = false,
  style = {},
}) => {
  if (!target) return <div className={className}>{children}</div>;

  if (target.type === "url") {
    return (
      <a
        className={className}
        onClick={onClick}
        href={target.url}
        target={openInNewWindow ? "_blank" : "_self"}
        rel={openInNewWindow ? "noopener noreferrer" : ""}
        style={style}
      >
        {children}
      </a>
    );
  }

  if (target.element && !!target.element.uri) {
    let uri = target.element?.uri;

    if (uri === "__home__") uri = "";

    return (
      <Link href={`/${uri}`}>
        <a
          className={className}
          onClick={onClick}
          target={openInNewWindow ? "_blank" : "_self"}
          rel={openInNewWindow ? "noopener noreferrer" : ""}
          style={style}
        >
          {children}
        </a>
      </Link>
    );
  }

  if (target.url) {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        href={target.url}
        className={className}
        target={openInNewWindow ? "_blank" : "_self"}
        rel={openInNewWindow ? "noopener noreferrer" : ""}
        onClick={onClick}
        style={style}
      >
        {children}
      </a>
    );
  }

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

export default Target;
