import clsx from "clsx";
import EntryCarousel from "./EntryCarousel";

const ProductsCarousel = ({ products }) => {
  return (
    <div>
      <EntryCarousel entries={products} showSection={false} scrollbar={false} />
    </div>
  );
};

export default ProductsCarousel;
